import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../../../Elements/Icon/Icon';
import {StreamFilterToggleStyled} from './styles';

const StreamFilterToggle = ({onClick}) => (
	// please note the space before 'Filter', _inside_ the span, _not_ between <Icon> and <span>,
	// due to the propTypes definition of IconButton
	// (a margin-left spacing would even be nicer)
	<StreamFilterToggleStyled className="ap-streamfilter-toggle" onClick={onClick}>
		<Icon name="filter" />
		<span> Filter</span>
	</StreamFilterToggleStyled>
);

StreamFilterToggle.propTypes = {
	onClick: PropTypes.func.isRequired
};

export default StreamFilterToggle;
