import styled, {css} from 'styled-components';
import {colors, sizes} from '../../../../styles/variables';
import {gradientCandyCarrot, media, withUnit} from '../../../../styles/utils';
import {Icon} from '../../../Elements/Icon/Icon';
import {FadeInOutStyled} from '../../../Layout/FadeInOut/styles';
import FancyToggle from '../../../Elements/ToggleButton';

const CategoryActiveStyles = css`
	position: relative;
	background-color: ${colors.carrot};
	${gradientCandyCarrot()};
	color: ${colors.white};

	&::after {
		content: '×';
		font-weight: 300;
		position: absolute;
		top: 50%;
		right: 0;
		transform: translate(-200%, -50%);
	}
`;

export const StreamFilterContentStyled = styled.div``;

export const FilterContainerStyled = styled.div`
	${props => (props.type === 'default' ? `display: none;` : `display: flex;`)};

	flex-wrap: wrap;
	justify-content: center;

	padding: ${withUnit(sizes.distance.base / 2)} ${withUnit(sizes.distance.base / 2)};

	${media.lg`
		display: flex;
		padding: ${withUnit(sizes.distance.base)} ${withUnit(sizes.distance.base * 3)};
	`};
`;

const IconActiveStyles = css`
	fill: ${colors.black};
	opacity: 0.1;
`;

const IconInactiveStyles = css`
	fill: ${colors.lightgrey};
	opacity: 0.6;
`;

export const IconStyled = styled(Icon)`
	position: absolute;
	top: 0.1em;
	left: 0.1em;
	font-size: 2.6em;
	z-index: -1;

	svg {
		${props => (props.active ? IconActiveStyles : IconInactiveStyles)};
	}
`;

export const CategoryStyled = styled.div`
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
	z-index: 1;
	margin: ${withUnit(sizes.distance.base / 4)};
	padding: 0 ${withUnit(sizes.distance.base)};
	border-radius: ${sizes.borderRadius};
	height: ${sizes.forms.buttonHeight};
	display: inline-flex;
	align-items: center;

	background-color: ${colors.white};
	font-size: 1em;
	font-weight: 600;
	cursor: pointer;

	${media.lg`
		padding: ${withUnit(sizes.distance.base / 3)} ${withUnit(sizes.distance.base * 1.5)};
	`};

	${props => (props.active ? CategoryActiveStyles : ``)};

	will-change: transform, box-shadow, background-color;
	transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, background-color 0.3s ease-out,
		background-position 0.3s ease-out;

	&:not([disabled]):hover {
		transform: translateY(-3px);
		box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
	}
`;

export const ResetButtonWrapper = styled(FadeInOutStyled)`
	display: flex;
	justify-content: center;

	${({show}) =>
		show
			? `
max - height: 50 px;
`
			: `
max - height: 0;
`}
`;

export const ResetButton = styled.a`
	font-size: 1em;
	font-weight: 600;
	cursor: pointer;
	color: ${colors.black};
	will-change: color;
	margin-bottom: ${withUnit(sizes.distance.base / 2)};

	${media.lg`
		margin-bottom: ${withUnit(sizes.distance.base)};
	`};

	&:hover {
		color: ${colors.carrot};
	}
`;

export const ToggleButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 0.8em;
	background: white;
	padding-bottom: 10px;
	padding-right: 10px;
	padding-left: 20px;
	padding-top: 20px;

	span {
		margin-right: 0.5rem;
		text-align: right;
	}
	${media.sm`
		font-size: 0.9em;
		background: transparent;
		padding-bottom: 0px;
		padding-right: 0px;
		padding-left: 0px;
		padding-top: 0px;
	`};
`;

export const ToggleButton = styled(FancyToggle)`
	font-weight: 600;
	cursor: pointer;
	padding-left: 40px;
	will-change: color;
	margin-bottom: ${withUnit(sizes.distance.base / 2)};
	${media.md`
	
`};
	${media.lg`
		margin-bottom: ${withUnit(sizes.distance.base)};
	`};
`;

export const StreamFilterCloseStyled = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: ${withUnit(sizes.distance.base / 2)};

	${media.lg`
		margin-bottom: ${withUnit(sizes.distance.base)};
	`};
`;
