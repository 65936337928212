import React from 'react';
import PropTypes from 'prop-types';
import {FadeInOutStyled} from '../../../Layout/FadeInOut/styles';

// fade in

export const FadeinWhenUpstairs = ({children, className, upstairs}) => (
	<FadeInOutStyled className={className} show={upstairs}>
		{children}
	</FadeInOutStyled>
);

FadeinWhenUpstairs.propTypes = {
	children: PropTypes.element,
	className: PropTypes.string,
	upstairs: PropTypes.bool.isRequired
};

FadeinWhenUpstairs.defaultProps = {
	children: null,
	className: ''
};

// fade out

export const FadeoutWhenUpstairs = ({children, className, upstairs}) => (
	<FadeInOutStyled className={className} show={!upstairs}>
		{children}
	</FadeInOutStyled>
);

FadeoutWhenUpstairs.propTypes = {
	children: PropTypes.element,
	className: PropTypes.string,
	upstairs: PropTypes.bool.isRequired
};

FadeoutWhenUpstairs.defaultProps = {
	children: null,
	className: ''
};
