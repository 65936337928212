import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {hideOverlay} from '../../../../state/actions/overlay';
import {StreamFilterCloseStyled} from './styles';
import Button from '../../../Elements/Button/Button';

const mapDispatchToProps = dispatch => ({
	onClick: () => {
		dispatch(hideOverlay());
	}
});

const StreamFilterClose = ({onClick}) => (
	<StreamFilterCloseStyled>
		<Button className="ap-streamfilter-close" onClick={onClick} text="Zurück zum Stream" />
	</StreamFilterCloseStyled>
);

StreamFilterClose.propTypes = {
	onClick: PropTypes.func.isRequired
};

export default connect(
	null,
	mapDispatchToProps
)(StreamFilterClose);
