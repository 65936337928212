import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from '../../../Elements/Icon/Icon';
import {StreamFilterToggleStyled} from './styles';

const StreamFilterToggleMobile = ({onClick, numberFilterCategories}) => (
	<StreamFilterToggleStyled className="ap-streamfilter-toggle-mobile" onClick={onClick}>
		<Icon name="filter" />
		<i>Nach Themen filtern {numberFilterCategories ? `(${numberFilterCategories})` : ``}</i>
	</StreamFilterToggleStyled>
);

StreamFilterToggleMobile.propTypes = {
	onClick: PropTypes.func.isRequired,
	numberFilterCategories: PropTypes.number
};

StreamFilterToggleMobile.defaultProps = {
	numberFilterCategories: 0
};

export default StreamFilterToggleMobile;
