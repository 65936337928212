import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {durations, currentActions} from './constants';
import {OverlayBackdrop, OverlayStyled, OverlayCloseStyled, themes, ResetStackContext} from './styles';
import {startScrolling, stopScrolling} from '../../../utils/scrollUtils';

/** @component */
export default class Overlay extends PureComponent {
	// we use this.state.show (as opposed to this.props.show) in order to delay some show/hide actions due to animations
	state = {
		show: this.props.show,
		currentAction: ''
	};

	refOfBackdrop = React.createRef();

	/**
	 * set state.show and state.currentAction, depending on change of props.show
	 */
	componentDidUpdate(prevProps) {
		const thisShow = this.props.show;
		const previousShow = prevProps.show;

		if (thisShow !== previousShow) {
			if (thisShow && previousShow) {
				// both are not nil and not equal strings ('nil' means here:  null, undefined or empty string);
				// this case will usually not happen, as it would mean that
				// an open overlay changes to another content without closing
				this.setState({
					show: thisShow
				});
			} else if (!previousShow && thisShow) {
				// change from nil to not-nil
				this.startShowing();
			} else {
				// change from not-nil to nil
				this.startHiding();
				setTimeout(this.finishHiding, durations.milliseconds.complete);
			}
		}
	}

	handleClickOnBackdrop = ev => {
		const {onRequestToHide} = this.props;
		// only react when the user clicked on the backdrop,
		// not when the event bubbled up from a child element
		if (this.refOfBackdrop.current === ev.target) {
			onRequestToHide();
		}
	};

	handleClickOnClose = () => {
		const {onRequestToHide} = this.props;
		onRequestToHide();
	};

	finishHiding = () => {
		const {onHidden} = this.props;
		this.setState({
			show: '',
			currentAction: ''
		});
		onHidden();
	};

	startHiding() {
		const {onStartHiding} = this.props;
		onStartHiding();
		this.setState({currentAction: currentActions.HIDING});
		startScrolling();
	}

	startShowing() {
		const {onOpening, show} = this.props;
		this.setState({
			show,
			currentAction: currentActions.SHOWING
		});
		onOpening();
		stopScrolling();
	}

	selectedChild() {
		const {children} = this.props;
		const {show} = this.state; // NOT this.props
		return React.Children.toArray(children).find(child => child.props.name === show);
	}

	render() {
		const {show} = this.state;
		if (show) {
			const {theme} = this.props;
			const {currentAction} = this.state;
			const selectedChild = this.selectedChild();

			return (
				<ResetStackContext>
					<OverlayBackdrop
						currentAction={currentAction}
						onClick={this.handleClickOnBackdrop}
						theme={theme}
						ref={this.refOfBackdrop}>
						<OverlayStyled currentAction={currentAction} theme={theme}>
							<OverlayCloseStyled onClick={this.handleClickOnClose} />
							{selectedChild}
						</OverlayStyled>
					</OverlayBackdrop>
				</ResetStackContext>
			);
		}
		return null;
	}
}

Overlay.propTypes = {
	onStartHiding: PropTypes.func,
	onHidden: PropTypes.func,
	onOpening: PropTypes.func,
	onRequestToHide: PropTypes.func,
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
	show: PropTypes.string,
	theme: PropTypes.oneOf(themes)
};

Overlay.defaultProps = {
	onStartHiding: () => {},
	onHidden: () => {},
	onOpening: () => {},
	onRequestToHide: () => {},
	children: null,
	show: null,
	theme: 'white'
};
