import styled, {css, keyframes} from 'styled-components';
import {colors, sizes, zindex} from '../../../styles/variables';
import {withUnit, media} from '../../../styles/utils';
import {durations, currentActions} from './constants';

const themeCode = {
	white: {
		bg: colors.white
	},
	snow: {
		bg: colors.snow
	}
};

export const themes = Object.keys(themeCode);

const SlideIn = keyframes`
  0% {
  	transform: translate(100%, 0);
  }
  
  100% {
  	transform: translate(0, 0);
  }
`;

const SlideOut = keyframes`
  0% {
  	transform: translate(0, 0);
  }
  
  100% {
	  transform: translate(100%, 0);
  }
`;

const FadeIn = keyframes`
  0% {
  	opacity: 0;
  }
  100% {
  	opacity: 1;
  }
`;

const FadeOut = keyframes`
  0% {
  	opacity: 1;
  }
  100% {
  	opacity: 0;
  }
`;

const animationFadeIn = css`
	animation: ${FadeIn} ${durations.strings.fading} forwards;
`;

const animationFadeOut = css`
	animation: ${FadeOut} ${durations.strings.fading} forwards ${durations.strings.delayBeforeFadeout};
`;

const animationSlideIn = css`
	animation: ${SlideIn} ${durations.strings.complete} forwards;
`;

const animationSlideOut = css`
	animation: ${SlideOut} ${durations.strings.complete} forwards;
`;

export const OverlayBackdrop = styled.div`
	${({currentAction}) =>
		currentAction === currentActions.SHOWING
			? animationFadeIn
			: currentAction === currentActions.HIDING
			? animationFadeOut
			: ''};
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: ${zindex.overlayBackdrop};

	background-color: ${colors.carrot80};
`;

export const OverlayStyled = styled.div`
	${({currentAction}) =>
		currentAction === currentActions.SHOWING
			? animationSlideIn
			: currentAction === currentActions.HIDING
			? animationSlideOut
			: ''};

	transform: translate(100%, 0);
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	padding: ${withUnit(sizes.distance.base / 2)};
	box-shadow: 1px -1px 20px rgba(0, 0, 0, 0.2);
	color: ${colors.black};
	overflow: auto;

	background-color: ${props => themeCode[props.theme].bg};
	opacity: 1;
	overflow: auto;

	${media.lg`
		padding: ${withUnit(sizes.distance.base)};
	`};
`;

export const OverlayCloseStyled = styled.div`
	position: absolute;
	display: block;
	top: ${withUnit(sizes.distance.base / 2)};
	right: ${withUnit(sizes.distance.base / 2)};
	width: 30px;
	height: 30px;
	border: 0;
	background: transparent;
	cursor: pointer;

	${media.lg`
		top: ${withUnit(sizes.distance.base * 2)};
		right: ${withUnit(sizes.distance.base * 2)};
	`};

	&:focus {
		outline: none;
	}

	&::before,
	&::after {
		pointer-events: none;
		display: block;
		position: absolute;
		left: 50%;
		top: 50%;
		transform-origin: center;
		width: 3px;
		height: 30px;
		background-color: ${colors.carrot};
		content: '';
		transform: translate(-50%, -50%);
	}

	&::before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&::after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
`;

export const ResetStackContext = styled.div`
	position: fixed;
	z-index: ${zindex.overlayBackdrop};
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
`;
