import {connect} from 'react-redux';
import {showOverlay} from '../../../../state/actions/overlay';
import StreamFilterToggle from './StreamFilterToggle';
import {KEY_STREAMFILTER_OVERLAY} from '../StreamFilter/constants';

const mapDispatchToProps = dispatch => ({
	onClick: () => dispatch(showOverlay(KEY_STREAMFILTER_OVERLAY))
});

export default connect(
	null,
	mapDispatchToProps
)(StreamFilterToggle);
