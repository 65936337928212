import * as Constants from '../constants';

export const addFilterCategory = (category) => ({
	type: Constants.ADD_FILTER_CATEGORY,
	payload: {
		category,
	},
});

export const removeFilterCategory = (category) => ({
	type: Constants.REMOVE_FILTER_CATEGORY,
	payload: {
		category,
	},
});

export const replaceFilterCategories = (categories) => ({
	type: Constants.REPLACE_FILTER_CATEGORIES,
	payload: {
		categories,
	},
});

export const removeAllFilterCategories = () => ({
	type: Constants.REMOVE_ALL_FILTER_CATEGORIES,
});

export const toggleFilterCategories = (showPersonal) => ({
	type: Constants.TOGGLE_FILTER_CATEGORIES,
	payload: {
		showPersonal,
	},
});
