import {HIDE_OVERLAY, SHOW_OVERLAY} from '../constants';

export const hideOverlay = () => ({
	type: HIDE_OVERLAY
});

export const showOverlay = name => ({
	type: SHOW_OVERLAY,
	payload: {
		name
	}
});
