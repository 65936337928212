import styled from 'styled-components';
import IconButton from '../../../Elements/IconButton/IconButton';
import {zindex, sizes, fonts} from '../../../../styles/variables';
import {withUnit, media} from '../../../../styles/utils';

export const StreamFilterToggleStyled = styled(IconButton)`
	z-index: ${zindex.streamFilterToggle};
	height: ${sizes.forms.buttonHeight};
	padding: 0 ${withUnit(sizes.distance.base)};
	margin-bottom: ${withUnit(sizes.distance.base)};
	box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
	transition: box-shadow 0.3s ease, transform 0.3s ease;
	font-size: 1em;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		transform: translateY(-3px);
		box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.25);
	}

	span {
		flex-shrink: 10000;
		+ span {
			margin-left: ${withUnit(sizes.distance.base / 3)};
			display: none;
			font-size: 1em;
			align-self: center;

			${media.md`
				display: inline;
			`};
		}
	}

	i {
		font-family: ${fonts.sans_serif};
		margin: 0 ${withUnit(sizes.distance.base / 4)};
		font-style: normal;
		flex-grow: 100;
		min-width: max-content;
		align-self: center;
	}

	&.ap-streamfilter-toggle-mobile {
		margin-top: ${withUnit(sizes.distance.base)};

		${media.lg`
			display: none;
		`};
	}
`;
