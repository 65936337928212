import React from 'react';
import {StaticQuery, graphql} from 'gatsby';
import PropTypes from 'prop-types';

import {connect} from 'react-redux';
import {
	addFilterCategory,
	removeAllFilterCategories,
	removeFilterCategory,
	toggleFilterCategories,
} from '../../../../state/actions/filterCategories';

import {notifyUser} from '../../../../state/actions/default';

import {
	StreamFilterContentStyled,
	FilterContainerStyled,
	CategoryStyled,
	IconStyled,
	ResetButton,
	ResetButtonWrapper,
	ToggleButton,
	ToggleButtonWrapper,
} from './styles';
import Headline from '../../../Elements/Headline/Headline';
import StreamFilterToggleMobile from '../StreamFilterToggle/ContainerMobile';

const StreamFilter = (props) => {
	const {actions, filterCategories, type, showPersonal, user} = props;

	return (
		<StaticQuery
			query={graphql`
				query {
					allWordpressCategory(filter: {slug: {nin: ["allgemein", "dossier", "news", "serie", "artikel"]}}) {
						edges {
							node {
								id
								slug
								name
								count
							}
						}
					}
				}
			`}
			render={(data) => {
				const handleCategoryClick = (event) => {
					const clickedCategory = event.target.dataset.slug;
					if (showPersonal === 'personal') {
						actions.toggleFilterCategories('general');
					}

					if (filterCategories.includes(clickedCategory)) {
						actions.removeFilterCategory(clickedCategory);
					} else {
						actions.addFilterCategory(clickedCategory);
					}
				};

				const resetFilter = () => {
					actions.removeAllFilterCategories();
				};

				const toggleFilter = () => {
					if (user === null) {
						actions.notifyUser({
							text:
								'Bitte melde dich an oder nutze die Registrierung, um diese Funktion nutzen zu können',
							button: 'OK',
						});
						return null;
					}
					if (user.nlf < 12) {
						actions.notifyUser({
							text: 'Um dir individuelle Vorschläge machen zu können, teile uns deine Präferenzen mit',
							button: 'Meine Angaben',
							target: {link: '/dashboard', state: {indexSelected: 3, targetSelected: 3}},
						});
						return null;
					}

					let show = 'general';
					if (showPersonal === 'general') {
						show = 'personal';
						actions.removeAllFilterCategories();
					}
					actions.toggleFilterCategories(show);
				};
				const streamFilterToggleMobileElement = type !== 'overlay' ? <StreamFilterToggleMobile /> : null;
				return (
					<>
						<StreamFilterContentStyled
							data={data}
							type={type}
							filterCategories={filterCategories}
							{...actions}>
							<Headline as="h1" text="Leben mit MS" center />
							<Headline as="h5" text="Welche Themen interessieren dich?" center />
							<ToggleButtonWrapper>
								{showPersonal === 'general' ? (
									<span>Aktivieren, um individuelle Vorschläge zu erhalten</span>
								) : (
									<span>Individuelle Vorschläge werden angezeigt</span>
								)}
								<ToggleButton
									className="ap-reset-filter"
									onClick={toggleFilter}
									readOnly
									checked={showPersonal !== 'general'}
								/>
							</ToggleButtonWrapper>
							<FilterContainerStyled showPadding center type={type}>
								{data.allWordpressCategory.edges.map(({node: item}) => (
									<CategoryStyled
										key={item.slug}
										data-slug={item.slug}
										data-id={item.id}
										active={filterCategories.includes(item.slug)}
										onClick={handleCategoryClick}>
										<IconStyled
											name={item.slug}
											active={filterCategories.includes(item.slug)}
											type="category"
										/>
										{item.name}
									</CategoryStyled>
								))}
							</FilterContainerStyled>
						</StreamFilterContentStyled>
						{streamFilterToggleMobileElement}
						<ResetButtonWrapper show={filterCategories.length}>
							<ResetButton className="ap-reset-filter" onClick={resetFilter}>
								Filter zurücksetzen
							</ResetButton>
						</ResetButtonWrapper>
					</>
				);
			}}
		/>
	);
};

StreamFilter.propTypes = {
	actions: PropTypes.objectOf(PropTypes.func),
	type: PropTypes.oneOf(['default', 'overlay']),
	filterCategories: PropTypes.arrayOf(PropTypes.string),
	showPersonal: PropTypes.string,
	addFilterCategory: PropTypes.func,
	removeAllFilterCategories: PropTypes.func,
	removeFilterCategory: PropTypes.func,
	user: PropTypes.objectOf(PropTypes.object),
	toggleFilterCategories: PropTypes.func,
	notifyUser: PropTypes.func,
};

StreamFilter.defaultProps = {
	actions: {},
	type: 'default',
	filterCategories: [],
	showPersonal: 'general',
	user: null,
	addFilterCategory: () => {},
	removeAllFilterCategories: () => {},
	removeFilterCategory: () => {},
	toggleFilterCategories: () => {},
	notifyUser: () => {},
};

const mapStateToProps = (state) => ({
	filterCategories: state.filterCategories,
	showPersonal: state.userManagement.showPersonal,
	user: state.userManagement.user,
});

const mapDispatchToProps = (dispatch) => ({
	actions: {
		addFilterCategory: (category) => dispatch(addFilterCategory(category)),
		removeAllFilterCategories: () => dispatch(removeAllFilterCategories()),
		removeFilterCategory: (category) => dispatch(removeFilterCategory(category)),
		toggleFilterCategories: (showPersonal) => dispatch(toggleFilterCategories(showPersonal)),
		notifyUser: (error) => dispatch(notifyUser(error)),
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(StreamFilter);
