import React from 'react';

import {CheckBoxWrapper, CheckBox, CheckBoxLabel} from './styles';

const FancyToggle = ({onClick, checked}) => (
	<CheckBoxWrapper>
		<CheckBox id="checkbox" type="checkbox" onClick={onClick} checked={checked} />
		<CheckBoxLabel htmlFor="checkbox" />
	</CheckBoxWrapper>
);

export default FancyToggle;
