import {connect} from 'react-redux';
import Overlay from './Overlay';
import {hideOverlay} from '../../../state/actions/overlay';
import {KEY_NAME} from '../../../state/reducers/overlay/constants';

const mapStateToProps = state => ({
	show: state.overlay && state.overlay[KEY_NAME]
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onRequestToHide: () => {
		const {onRequestToHide} = ownProps;
		if (onRequestToHide) {
			onRequestToHide();
		}
		dispatch(hideOverlay());
	}
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Overlay);
