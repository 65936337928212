import React from 'react';
import Overlay from '../../../Layout/Overlay/Container';
import StreamFilter from './StreamFilter';
import StreamFilterClose from './StreamFilterClose';
import {KEY_STREAMFILTER_OVERLAY} from './constants';
import {ID_POST_LIST} from '../PostList/constants';
import {scrollWindowUp} from '../../../../utils/scrollUtils';

const StreamFilterInOverlay = () => {
	const scrollUp = () => {
		const scrollToHeadlineOfPostList = true; // we can decide whether we want to scroll to the headline or to the document-top
		if (scrollToHeadlineOfPostList) {
			scrollWindowUp(ID_POST_LIST);
		} else {
			// scroll to document-top
			scrollWindowUp();
		}
	};

	const onHidden = () => {
		scrollUp();
	};

	return (
		<Overlay theme="snow" onHidden={onHidden}>
			<div name={KEY_STREAMFILTER_OVERLAY}>
				<StreamFilter type="overlay" />
				<StreamFilterClose />
			</div>
		</Overlay>
	);
};

export default StreamFilterInOverlay;
