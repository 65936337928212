import styled from 'styled-components';

import {
	FadeinWhenUpstairs as FadeinWhenUpstairsOriginal,
	FadeoutWhenUpstairs as FadeoutWhenUpstairsOriginal
} from '../../Scrolling/HandleUpstairs/FadeInOutWhenUpstairs';

import {media, withUnit} from '../../../../styles/utils';
import {sizes, zindex} from '../../../../styles/variables';

/**
 * This is the 'normal' FadeinWhenUpstairs combined with additional css
 * that is used to reset the stack context (compare ResetStackContext in Overlay/styles.js)
 */
export const FadeinWhenUpstairs = styled(FadeinWhenUpstairsOriginal)`
	z-index: ${zindex.streamFilterToggle};
`;

/**
 * This is the 'normal' FadeoutWhenUpstairs combined with additional css
 * that is used to reset the stack context (compare ResetStackContext in Overlay/styles.js)
 */
export const FadeoutWhenUpstairs = styled(FadeoutWhenUpstairsOriginal)`
	position: fixed;
	z-index: ${zindex.streamFilterToggle};
	top: ${withUnit(sizes.navbar.sm.upstairs)};
	right: 0;
	padding: ${withUnit(sizes.distance.base / 2)};

	${media.lg`
		top: ${withUnit(sizes.navbar.upstairs)};
		right: 0;
		padding: ${withUnit(sizes.distance.base * 2)};
	`}
`;
