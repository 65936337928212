import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import {connect, useDispatch} from 'react-redux';
import Layout from '../components/Layout/Layout';
import Section from '../components/Layout/Section/Section';
import {BlogTemplateStyled} from './styles';
import StreamFilterToggle from '../components/Container/Stream/StreamFilterToggle/Container';
import StreamFilterInOverlay from '../components/Container/Stream/StreamFilter/StreamFilterInOverlay';
import StreamFilter from '../components/Container/Stream/StreamFilter/StreamFilter';
import {FadeoutWhenUpstairs} from '../components/Container/Stream/StreamFilterToggle/FadeInOutWhenUpstairs';

import WindowScrollListener from '../components/Container/Scrolling/WindowScrollListener';
import Meta from '../components/Layout/Meta';
import {PostListContainerWithChangeEffect as PostList} from '../components/Container/Stream/PostList/Container';
import ReleaseNumber from '../components/Elements/ReleaseNumber/ReleaseNumber';
import ContactSection from '../components/Content/ContactSection/ContactSection';
import {replaceFilterCategories} from '../state/actions/filterCategories';

const IndexPage = ({data, location, showPersonal}) => {
	const {edges: posts} = data.allWordpressPost;
	const {wordpressPage: page} = data;
	const [mounted, setMounted] = useState(false);
	const dispatch = useDispatch();
	const slicce = location?.hash.slice(1).split('-');
	useEffect(() => {
		setMounted(true);
		if (slicce.length >= 1 && slicce[0].trim().length >= 1) {
			dispatch(replaceFilterCategories(slicce));
		}
	}, []);

	return (
		<>
			<Meta />
			<Layout location={location}>
				<StreamFilterInOverlay />
				<BlogTemplateStyled>
					<Section showPadding theme="grey">
						<WindowScrollListener threshold={400} mounted={mounted}>
							<FadeoutWhenUpstairs>
								<StreamFilterToggle />
							</FadeoutWhenUpstairs>
						</WindowScrollListener>
						<StreamFilter />
						<PostList
							posts={posts}
							title="Dein Stream"
							section={showPersonal === 'personal' ? 'nlfTeaserList' : 'fullTeaserList'}
						/>

						<ReleaseNumber releaseNumber={page.acf.release_number} />
					</Section>
				</BlogTemplateStyled>

				<ContactSection />
			</Layout>
		</>
	);
};

const mapStateToProps = (state) => ({
	showPersonal: state.userManagement.showPersonal,
});

IndexPage.propTypes = {
	data: PropTypes.objectOf(PropTypes.object).isRequired,
	pageContext: PropTypes.shape({
		currentPage: PropTypes.number,
		numPages: PropTypes.number,
	}),
	location: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
	showPersonal: PropTypes.string,
};

IndexPage.defaultProps = {
	pageContext: null,
	showPersonal: null,
};

export default connect(mapStateToProps)(IndexPage);

export const pageQuery = graphql`
	query BlogById($id: String!) {
		wordpressPage(id: {eq: $id}) {
			acf {
				release_number
			}
		}
		allWordpressPost(
			sort: {fields: [date], order: DESC}
			filter: {categories: {elemMatch: {name: {nin: ["Dossier", "Artikel", "News", "Serie"]}}}}
		) {
			edges {
				node {
					id
					wordpress_id
					title
					excerpt
					primary_category {
						name
					}
					acf {
						hidden
						persona
					}
					categories {
						id
						name
					}
					date(formatString: "MMMM DD, YYYY")
					slug
					path
					featured_media {
						localFile {
							childImageSharp {
								id
								fluid(srcSetBreakpoints: [800, 250], quality: 80) {
									...GatsbyImageSharpFluid_noBase64
								}
							}
						}
					}
				}
			}
		}
	}
`;
