import styled from 'styled-components';

export const FadeInOutStyled = styled.div`
	overflow: hidden;

	${({show}) =>
		show
			? `
			opacity: 1;
			visibility: visible;
			transition: 0.5s ease-out 0.5s, max-height 0.5s ease-out 0s;
			`
			: `
			opacity: 0;
			visibility: hidden;
			transition: 0.5s ease-out 0s, max-height 0.5s ease-out 0.5s;
			`}
`;
