import {connect} from 'react-redux';
import {showOverlay} from '../../../../state/actions/overlay';
import StreamFilterToggleMobile from './StreamFilterToggleMobile';
import {KEY_STREAMFILTER_OVERLAY} from '../StreamFilter/constants';

const mapDispatchToProps = dispatch => ({
	onClick: () => dispatch(showOverlay(KEY_STREAMFILTER_OVERLAY))
});

const mapStateToProps = state => {
	return {
		numberFilterCategories: (state.filterCategories || []).length
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(StreamFilterToggleMobile);
