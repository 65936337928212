import {createDurationsObject} from '../../../utils/duration';

export const currentActions = {
	SHOWING: 'showing',
	HIDING: 'hiding'
};

// calculate constants-object (number, strings) for durations, based on constants 'complete' and 'fading'
export const durations = createDurationsObject({
	complete: 700,
	fading: 300,
	delayBeforeFadeout: 700 - 300
	//
	// to investigate the animation in more detail, use these values:
	// complete: 2400,
	// fading: 1500,
	// delayBeforeFadeout: 2400 - 1500
});
