import styled from 'styled-components';
import {gradientCandyCarrot} from '../../../styles/utils';

export const CheckBoxWrapper = styled.div`
	position: relative;
`;
export const CheckBoxLabel = styled.label`
	position: absolute;
	top: 0;
	left: 0;
	width: 2.5em;
	height: 1.5em;
    border-radius: 1em;
    border: 1px solid rgba(0,0,0,0.3);
	background: white;
	cursor: pointer;
	&::after {
		content: '';
		display: block;
		border-radius: 50%;
		width: 1.15em;
		height: 1.15em;
		margin: 0.15em;
		background: black;
		box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
		transition: 0.2s;
	}
`;
export const CheckBox = styled.input`
	opacity: 0;
	z-index: 1;
    width: 2.5em;
    
	height: 1.5em;
	border-radius: 1em;
	&:checked + ${CheckBoxLabel} {
		&::after {
			content: '';
			display: block;
			border-radius: 50%;
			${gradientCandyCarrot()};
			border-radius: 50%;
			width: 1.15em;
			height: 1.15em;
			margin: 0.15em;
			margin-left: 1.1em;
			transition: 0.2s;
		}
	}
`;
